<template>
  <el-card>
    <el-row
      class="filter-wrap"
      :gutter="10"
    >
      <el-col :span="4">
        <div class="flex-start">
          <el-input
            v-model="listQuery.searchStr"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
        </div>
      </el-col>
      <el-col :span="3">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="个人姓名"
        prop="username"
      />
      <el-table-column
        prop="companyName"
        :show-overflow-tooltip="true"
        label="公司名称"
      />
      <el-table-column
        prop="companyEmail"
        :show-overflow-tooltip="true"
        label="公司邮箱"
      />
      <el-table-column
        prop="contactPhone"
        label="手机号"
      >
        <template slot-scope="{row}">
          {{ row.userPhone }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="proposeContent"
        label="意见"
      />
      <el-table-column
        prop="proposeTime"
        label="申请时间"
      />
      <el-table-column
        prop="status"
        label="状态"
      >
        <template v-slot:="scope">
          <el-tag
            v-if="scope.row.status === 2"
            effect="dark"
          >
            已处理
          </el-tag>
          <el-tag
            v-else
            type="danger"
            size="small"
          >
            待处理
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="processor"
        label="处理人"
      />
      <el-table-column
        prop="processingTime"
        label="处理时间"
      />
      <el-table-column
        prop="processResult"
        label="处理结果"
      />
      <el-table-column
        label="操作"
        width="135px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="dialogShow(row)"
          >
            <el-tooltip
              content="处理"
              placement="top"
              effect="light"
            >
              <i class="el-icon-s-operation" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delEnterprise(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <ProposeRun
      ref="applyRun"
      @handleFilter="getList"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination'
import { error, success } from '@core/utils/utils'
import { ProposePage, ProposeDel } from '@/api/enterprises/enterprises'
import ProposeRun from '@/views/enterprises/ProposeRun.vue'

export default {
  components: {
    pagination,
    ProposeRun,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    dialogShow(row) {
      this.$refs.applyRun.dialogVisible = true
      this.$refs.applyRun.form.id = Number(row.id)
      this.$refs.applyRun.form.processRemark = row.processRemark
    },
    hideTel(tel) {
      const reg = /^(\d{3})\d{4}(\d{4})$/
      return tel.replace(reg, '$1****$2')
    },
    delEnterprise(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        ProposeDel({ id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      ProposePage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
