<template>
  <el-dialog
    title="试用处理"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="100px"
    >
      <b-row>
        <b-col
          md="12"
        >
          <el-form-item
            prop="processResult"
            :rules="[ { required: true, message: '处理结果不能为空', trigger: 'change' }]"
            label="处理结果"
          >
            <el-input
              v-model="form.processResult"
              placeholder="请输入内容"
              type="textarea"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="text-center mt20">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { ProposeProcess } from '@/api/enterprises/enterprises'
export default {
  data() {
    return {
      statusOptions: ['试用用户', '地理和部署', '正式用户', '持续跟进'],
      loading: false,
      dialogVisible: false,
      form: {
        id: 0,
        processResult: '',
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        id: 0,
        processStatus: '',
        processRemark: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          ProposeProcess(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
 .icon-btn{
   position: relative;
   top: 10px;
   font-size: 20px;
   cursor: pointer;
 }
</style>
